import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const AddIntrospection = React.lazy(() => import("./AddIntrospection"));
const IntrospectionDetails = React.lazy(() => import("./IntrospectionDetails"));
const IntrospectionUserDetails = React.lazy(() => import("./IntrospectionUserDetails"));
export const introspectionConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/introspection",
        element: <AddIntrospection />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/introspection/:introspectionId",
        element: <IntrospectionDetails />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/introspection/:introspectionId/:userId",
        element: <IntrospectionUserDetails />,
    },
];
